import { tReducedLabel, tShortLabel } from 'i18n/utils';
import _ from 'lodash';
import moment from 'moment';
import momentDurationFormat from 'moment-duration-format';

momentDurationFormat(moment);

export const useGoodTranslator = (t, labelType, field) => {
  switch (labelType) {
    case 'short':
      return tShortLabel(field, t);
    case 'reduced':
      return tReducedLabel(field, t);
    default:
      return t(field.label);
  }
};

export const standardValueFormat = (t, config, field, dataSet, labelType = null) => {
  try {
    const fieldRawValue = dataSet[field.field];
    const fieldFullValue = _.find(field.options, { value: fieldRawValue });
    // the "full value" is when it's defined not only by a value, but by an actual object containing its label
    // that is mainly used for parameters, and for "enum" type of results (validated / partially validated / rejected)

    if (fieldFullValue) return t(fieldFullValue.label);

    // when there is no "full value", it means there is no label.
    // In which case the data is raw, and needs to be formatted.
    switch (field.type) {
      case 'time':
        return moment
          .duration(fieldRawValue, 'milliseconds')
          .format(`s.SS [${t('Common_UnitSecond§Short')}]`, { trim: false });
      case 'totalTime':
        return moment.duration(fieldRawValue, 'milliseconds').format('mm:ss', { trim: false });
      case 'enum':
        return _.find(field.options, { value: fieldRawValue })
          ? useGoodTranslator(t, labelType, _.find(field.options, { value: fieldRawValue }))
          : '-';
      case 'percent':
        return `${parseFloat(fieldRawValue * 100).toFixed(0)} %`;
      case 'float':
        return `${parseFloat(fieldRawValue).toFixed(2)}`;
      case 'boolean':
        if (field.options)
          return fieldRawValue
            .map((item) =>
              useGoodTranslator(
                t,
                labelType,
                config[field.field].find((entry) => entry.value === item),
              ),
            )
            .join(', ');
        return fieldRawValue ? t('Common_Yes') : t('Common_No');
      default:
        return `${fieldRawValue}`;
    }
  } catch (error) {
    console.log(`error standardValueFormat with field ${field.field}`, error);
    return '-';
  }
};

export const prepareValueForDisplay = (t, config, field, dataSet, labelType, formatter = null) => {
  try {
    let formatterResult = null;
    if (dataSet && formatter) {
      formatterResult = formatter.formatResult(field.field, dataSet);
    }

    const fieldRawValue = dataSet[field.field];
    if (fieldRawValue != null) {
      const fieldFullValue = _.find(field.options, { value: fieldRawValue });

      // Only used for parameter using remote value,
      // whose label is unsync and needs to be stored in a specific label (e.g.: scenario)
      if (field.remoteLinks && field.remoteLinks.labelFieldName) {
        return dataSet[field.remoteLinks.labelFieldName];
      }

      if (fieldFullValue)
        // the value is fully described in an array, therefore must be translated. Valid only for "enum" result types and all parameters
        return useGoodTranslator(t, labelType, fieldFullValue);
      if (formatterResult != null) return formatterResult;
      return dataSet ? standardValueFormat(t, config, field, dataSet, labelType) : '-';
    }
    return t("Common_Result_Undefined");
  } catch (error) {
    console.log(`error prepareValueForDisplay with field ${field.field}`, error);
    return '-';
  }
};
